import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const FeaturedActivity = ({ title, path, featuredImage, featuredAlt }) => (
    <div className="col-xs-12 col-sm-6">
        <div className="card activity-card">
            <Link to={ path }>
                <GatsbyImage image={ featuredImage }
                     alt={ featuredAlt } /></Link>
            <div className="card-body">
                <Link to={ path }><h2 className="card-title">{ title }</h2></Link>
            </div>
        </div>
    </div>
);

export default FeaturedActivity;