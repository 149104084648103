import * as React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import FeaturedActivity from "../components/featuredActivity";

export const LatestActivitiesQuery = graphql`
  query LatestActivities {
  allMarkdownRemark (sort: {order: DESC, fields: frontmatter___date}, limit: 4) {
    edges {
      node {
        frontmatter {
          activityId
          title
          path
          featuredImage {
            childImageSharp {
             gatsbyImageData(
               width: 450
               placeholder: BLURRED
               formats: [AUTO, WEBP, AVIF]
             )
            }
          }
          featuredAlt
        }
      }
    }
  }
}
`
const IndexPage = ({ data }) => (
    <Layout>
        <Seo title="Ten minutes for Mama" />

        <section className="bg-primary text-center main" id="main">
            <div className="container">
                <h1>Every mama needs some 'me' time</h1>
                <p>Want ten minutes to make a cuppa and actually drink it? We've got plenty of ideas.</p>

                <hr className="star-dark" />

                <p className="lead">Screen-free activities for young kids</p>

                <div className="row no-gutters">
                    {
                        data.allMarkdownRemark.edges.map(activity => {
                            const { activityId, title, path, featuredAlt } = activity.node.frontmatter;

                            return (
                                <FeaturedActivity
                                    key={activityId}
                                    title={title}
                                    path={path}
                                    featuredImage={getImage(activity.node.frontmatter.featuredImage)}
                                    featuredAlt={featuredAlt}
                                />
                            )
                        })
                    }
                </div>

                <form className="another-ten" action="activities">
                    <p><button className="btn btn-secondary gimmeTen"
                               type="submit">View all</button></p>
                </form>
            </div>
        </section>

        <section className="text-center" id="about">
            <div className="container">
                <h2 className="text-uppercase text-secondary mb-0">About</h2>
                <hr className="star-light mb-5" />
                    <p className="lead">Hi, I'm Kate.</p>
                    <p>I'm a web developer and mother of three. "Ten minutes for Mama" is my offering to the
                        parents of the world. We all deserve a little time off.</p>
                    <p>As a mother of babies, toddlers and young children I didn't have much time for myself. Those precious
                        moments when I could finish a cup of tea or wash my face were few and far between. Now that my kids are
                        relatively grown up (my youngest is 7) I have taken the time to put together this list of
                        fun, engaging activities and play ideas that many a toddler will be able to do independently or
                        with just a little bit of help.</p>
                    <p>These are the activies that gave me moments of sanity in my
                        otherwise hectic time of parenting young 'uns!</p>
                    <p>My aim was always to reduce screen-time for my crew and to play outside as much as possible. Using
                        natural elements, craft supplies and things you probably have around the house means most of these
                        activities can be done with little or no cost and with really not much forethought (something I
                        distinctly lacked a few years ago).</p>
                    <p>Enjoy! And <a href="mailto:kate@currie.com.au?subject=Ten Minutes for Mama">send me a message</a>, I'd love to hear how these work for you.</p>
            </div>
        </section>
    </Layout>
);

export default IndexPage